.LectureList {
  padding: 2rem 4rem;
  .lecture-item {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    flex-wrap: wrap;

    .lecture-title {
      width: 75%;
      font-size: 36px;
      color: #616161;
      font-weight: 500;
      word-break: break-all;
    }

    .lecture-status {
      width: 25%;
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      margin-left: auto;
      word-break: keep-all;

      .lecture-completed {
        background: #616161;
        border-radius: 32px;
        width: 100%;
        padding: 0.25rem 0.5rem;
      }
      .lecture-in-progress {
        background: #07fdc0;
        border-radius: 32px;
        width: 100%;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}

@media all and (max-width: 1710px) {
  .LectureList {
    .lecture-item {
      .lecture-title {
        font-size: 32px;
      }
    }
  }
}

@media all and (max-width: 1500px) {
  .LectureList {
    padding: 2rem;
  }
}

@media all and (max-width: 1024px) {
  .LectureList {
    .lecture-item {
      align-items: flex-start;
      flex-direction: column;
      .lecture-title {
        width: 100%;
        font-size: 20px;
      }

      .lecture-status {
        margin-top: 0.5rem;
        width: 100%;
        font-size: 12px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .LectureList {
    padding: 1.5rem;
    .lecture-item {
      .lecture-title {
        font-size: 36px;
      }

      .lecture-status {
        font-size: 24px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .LectureList {
    .lecture-item {
      .lecture-title {
        font-size: 22px;
      }

      .lecture-status {
        font-size: 16px;
      }
    }
  }
}
