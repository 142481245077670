.NoticeList {
  width: 100%;
  flex: 1;

  .notice-header {
    display: flex;
    align-items: center;
    padding: 1rem 4rem;
    position: relative;

    .notice-header-title {
      font-size: 40px;
      color: #08ddfd;
      font-weight: 500;
      flex: 1;
      text-align: center;
    }

    .notice-write-btn {
      position: absolute;
      right: 1rem;
      font-size: 28px;
      border-radius: 32px;
      color: #07fdc0;
      border: 3px solid #07fdc0;
      padding: 0.25rem 2.5rem;
      cursor: pointer;
    }
  }

  .notice-list {
    background: #f1f1f2;
    padding: 0 4rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    .notice-not-found {
      text-align: center;
      font-size: 30px;
      padding: 6rem 2rem;
    }
    .notice-item {
      padding: 0 3rem;
      .notice-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        cursor: pointer;
        position: relative;

        .notice-num {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #08ddfd;
          font-size: 28px;
          color: #fff;
          font-weight: 300;
          width: 2.5rem;
          height: 2.5rem;
          // position: absolute;
          // left: 0;
        }

        .notice-title {
          flex: 1;
          text-align: center;
          font-size: 30px;
          color: #000;
          font-weight: 500;
          padding: 0 1rem;
        }

        .notice-right {
          display: flex;
          justify-content: center;
          align-items: center;
          // position: absolute;
          // right: 0;

          .notice-date {
            font-size: 30px;
            color: #000;
            font-weight: 300;
            margin-right: 1rem;
          }

          .notice-arrow {
            img {
              vertical-align: baseline;
            }
          }
        }
      }

      .notice-content {
        font-size: 22px;
        color: #000;
        font-weight: 300;
        text-align: left;
        word-break: keep-all;
        line-height: 1.5;
        border-top: 1px solid #3f3f3f;
        padding: 2rem;
      }
    }

    .is-selected-notice {
      background: #fff;
      border-radius: 16px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }
  }

  .notice-pagination {
    padding: 2rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    background: #f1f1f2;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      .previous {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: #08ddfd;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
        cursor: pointer;

        a {
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
        }
      }
      .page {
        width: 1rem;
        height: 1rem;
        background: #909090;
        border-radius: 50%;
        margin: 0 1rem;
        cursor: pointer;
        a {
          color: transparent;
          width: 1rem;
          height: 1rem;
          display: inline-block;
        }
      }

      .selected {
        background: #08ddfd;
      }
      .next {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: #08ddfd;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-left: 0.5rem;
        cursor: pointer;

        a {
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .NoticeList {
    .notice-header {
      .notice-header-title {
        font-size: 22px;
      }

      .notice-write-btn {
        font-size: 14px;
      }
    }

    .notice-list {
      padding: 0 2rem;
      padding-top: 1rem;
      padding-bottom: 2rem;

      .notice-item {
        .notice-title-box {
          .notice-num {
            font-size: 16px;
            width: 2rem;
            height: 2rem;
          }

          .notice-title {
            font-size: 16px;
          }

          .notice-right {
            .notice-date {
              font-size: 16px;
              margin-right: 0.5rem;
            }

            .notice-arrow {
              display: flex;

              img {
                max-width: 60%;
              }
            }
          }
        }
        .notice-content {
          font-size: 12px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .NoticeList {
    .notice-header {
      .notice-header-title {
        font-size: 40px;
      }

      .notice-write-btn {
        font-size: 28px;
        padding: 0.25rem 1.5rem;
      }
    }

    .notice-list {
      padding: 0 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .notice-item {
        padding: 0 1rem;

        .notice-title-box {
          .notice-num {
            font-size: 30px;
            width: 3rem;
            height: 3rem;
          }

          .notice-title {
            font-size: 26px;
            word-break: keep-all;
          }

          .notice-right {
            .notice-date {
              font-size: 20px;
            }

            .notice-arrow {
              img {
                max-width: 100%;
              }
            }
          }
        }
        .notice-content {
          font-size: 18px;
        }
      }
    }

    .notice-pagination {
      ul {
        .page {
          margin: 0 0.5rem;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .NoticeList {
    .notice-header {
      .notice-header-title {
        font-size: 26px;
      }

      .notice-write-btn {
        font-size: 18px;
      }
    }

    .notice-list {
      padding: 0 0.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .notice-item {
        .notice-title-box {
          .notice-num {
            font-size: 18px;
            width: 2rem;
            height: 2rem;
          }

          .notice-title {
            font-size: 16px;
            word-break: keep-all;
          }

          .notice-right {
            .notice-date {
              font-size: 14px;
            }

            .notice-arrow {
              img {
                max-width: 60%;
              }
            }
          }
        }
        .notice-content {
          font-size: 12px;
        }
      }
    }

    .notice-pagination {
      ul {
        .previous {
          display: none;
        }

        .next {
          display: none;
        }
      }
    }
  }
}
