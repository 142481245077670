.LoginBanner {
  flex: 1;
  width: 100%;

  .login-banner-bg {
    display: flex;
    align-items: flex-end;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .login-banner-title {
      font-weight: 900;
      font-size: 133px;
      color: #fff;
      line-height: 1.15;
      margin-left: 4rem;
      margin-bottom: 4rem;
    }
  }
}

@media all and (max-width: 1024px) {
  .LoginBanner {
    .login-banner-bg {
      .login-banner-title {
        font-size: 72px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .LoginBanner {
    .login-banner-bg {
      height: 50vh;

      .login-banner-title {
        font-size: 80px;
        margin-left: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .LoginBanner {
    .login-banner-bg {
      height: 40vh;

      .login-banner-title {
        font-size: 52px;
        margin-left: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
