.CmsMajorTopicList {
  display: flex;
  height: 38vh;

  .cms-banner-bg {
    position: relative;
    flex: 0.35;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .cms-banner-overlay {
      height: 100%;
      background: rgba(0, 0, 0, 0.24);
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-size: 48px;
        color: #fff;
        font-weight: 800;
        padding: 3rem 14rem 2rem 4rem;
        word-break: keep-all;
        height: 100%;
      }
    }
  }

  .major-topic-list {
    display: flex;
    flex: 1;

    .major-topic-0 {
      background: #08ddfd;
    }
    .major-topic-1 {
      background: #00fff0;
    }
    .major-topic-2 {
      background: #07fdc0;
    }

    .major-topic-item {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      h3 {
        font-size: 48px;
        font-weight: 800;
        color: #fff;
        margin-right: auto;
        margin-left: 2rem;
      }
      .major-topic-img {
        margin-left: auto;
        margin-right: 2rem;
        img {
          max-width: 100%;
        }

        .major-topic-pc {
          display: block;
        }

        .major-topic-mobile {
          display: none;
        }
      }
    }

    .is-seleted-topic {
      h3 {
        color: #1d4b9f;
      }
    }
  }
}

@media all and (max-width: 1500px) {
  .CmsMajorTopicList {
    .cms-banner-bg {
      .cms-banner-overlay {
        h3 {
          padding: 2rem;
        }
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .CmsMajorTopicList {
    .cms-banner-bg {
      .cms-banner-overlay {
        h3 {
          font-size: 38px;
        }
      }
    }
    .major-topic-list {
      .major-topic-item {
        h3 {
          margin-right: unset;
          margin-left: unset;
          font-size: 38px;
          text-align: center;
        }

        .major-topic-img {
          margin-left: unset;
          margin-right: unset;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .CmsMajorTopicList {
    .cms-banner-bg {
      .cms-banner-overlay {
        h3 {
          font-size: 26px;
        }
      }
    }
    .major-topic-list {
      .major-topic-item {
        h3 {
          font-size: 26px;
          margin-bottom: 2rem;
        }

        .major-topic-img {
          img {
            max-width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .CmsMajorTopicList {
    flex-direction: column;
    height: auto !important;

    .cms-banner-bg {
      .cms-banner-overlay {
        justify-content: center;
        padding: 3rem 0;

        h3 {
          font-size: 44px;
        }
      }
    }

    .major-topic-list {
      .major-topic-item {
        flex-direction: column-reverse;
        padding: 1.5rem 0;

        h3 {
          font-size: 24px;
          margin-top: 0.5rem;
          margin-bottom: unset;
        }

        .major-topic-img {
          .major-topic-pc {
            display: none;
          }

          .major-topic-mobile {
            display: block;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .CmsMajorTopicList {
    .cms-banner-bg {
      .cms-banner-overlay {
        h3 {
          font-size: 30px;
        }
      }
    }

    .major-topic-list {
      .major-topic-item {
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}
