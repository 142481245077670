.LoginFlexBox {
  display: flex;
  align-items: center;
}

@media all and (max-width: 768px) {
  .LoginFlexBox {
    flex-direction: column-reverse;
  }
}
