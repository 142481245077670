@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.03125rem;
  box-sizing: border-box;
}

body,
button,
input,
select,
table,
textarea {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 0.5px;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: inherit;
}

body,
button,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

button,
input,
textarea {
  transition: all 0.2s linear;
  -webkit-appearance: none;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

img {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  vertical-align: bottom;
}

.keep-all {
  word-break: keep-all;
}

#root {
  height: 100%;
}

.chkbox {
  display: block;
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  font-size: 24px;
  color: #d5d5d5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

/* Hide the browser's default checkbox */
.chkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  border-radius: 6px;
  border: 2px solid #d5d5d5;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.chkbox:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.chkbox input:checked ~ .checkmark {
  border: 2px solid #07fdc0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkbox .checkmark:after {
  left: 10px;
  top: 2px;
  width: 10px;
  height: 18px;
  border: solid #07fdc0;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.popup-content {
  border-radius: 10px;
}

@media all and (max-width: 1024px) {
  .chkbox {
    font-size: 14px;
  }
  .checkmark {
    top: -6px;
  }
}

@media all and (max-width: 768px) {
  .chkbox {
    font-size: 24px;
  }
  .checkmark {
    top: 0px;
  }
}

@media all and (max-width: 500px) {
  .chkbox {
    font-size: 16px;
  }
  .checkmark {
    top: -6px;
  }
}
