.DropDown {
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  .drop-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #d5d5d5;
    font-size: 24px;
    padding: 1rem 1.5rem;
    cursor: pointer;

    .Dropdown-placeholder {
      text-align: left;
    }

    .Dropdown-arrow-wrapper {
      display: flex;
    }
  }

  .Dropdown-menu {
    border: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .Dropdown-option {
      border: 2px solid #d5d5d5;
      margin: 0.25rem 0;
      padding: 1rem 1.5rem;
    }
  }
}
