.Header {
  background: #fff;

  .header-wrap {
    display: flex;
    padding: 1rem 2rem;
    align-content: center;
    align-items: center;

    .header-title {
      font-size: 28px;
      font-weight: 800;
      color: #616161;
      cursor: pointer;
    }

    .header-nav {
      ul {
        display: flex;
        font-size: 28px;
        color: #616161;

        li {
          margin-left: 6rem;
          cursor: pointer;
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      margin-left: auto;

      .go-to-cms {
        display: flex;
        font-size: 24px;
        color: #08ddfd;
        cursor: pointer;
        margin-right: 3rem;
        align-items: center;

        img {
          margin-left: 1rem;
        }
      }

      .go-to-lms {
        display: flex;
        font-size: 24px;
        color: #07fdc0;
        cursor: pointer;
        margin-right: 3rem;
        align-items: center;

        img {
          margin-left: 1rem;
        }
      }

      .header-userinfo {
        img {
          cursor: pointer;
        }
      }
    }
  }

  .header-mobile-wrap {
    display: none;
    align-items: center;
    padding: 1.5rem;

    .haeder-menu {
      cursor: pointer;
    }

    .header-mobile-title {
      font-size: 26px;
      font-weight: 800;
      color: #616161;
      cursor: pointer;
      margin-left: auto;
      text-align: right;
    }
  }

  .MobileMenu {
    z-index: 10000;
    display: none;

    .mobile-menu-overlay {
      position: fixed;
      z-index: 1000;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
      transform: translate3d(100%, 0px, 0px);
    }

    .mobile-menu-slide {
      position: fixed;
      right: inherit;
      z-index: 1100;
      width: 80%;
      height: 100%;
      transition: all 0.5s ease 0s;
      transform: translate3d(-100%, 0px, 0px);

      .mobile-menu-content {
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 2.5em 4em 2rem 1rem;
        background: #fff;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;

        .user-thumbnail {
          width: 100%;
          text-align: center;
          margin-bottom: 2rem;
          img {
            max-width: 40%;
          }
        }

        .header-btns {
          display: flex;
          font-size: 30px;
          text-align: center;

          .header-myinfo {
            width: 50%;
            color: #08ddfd;
            border: 1px solid #08ddfd;
            border-radius: 64px;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
            cursor: pointer;

            a {
              display: inline-block;
              width: 100%;
            }
          }

          .header-logout {
            width: 50%;
            color: #fff;
            border: 1px solid #616161;
            background: #616161;
            border-radius: 64px;
            padding: 0.25rem 0.5rem;
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        .cms-lecture-nav {
          text-align: center;
          margin-top: 4rem;

          ul {
            font-size: 40px;
            font-weight: 300;
            color: #616161;

            li {
              margin-bottom: 3rem;
              cursor: pointer;
              padding-bottom: 0.5rem;
            }

            .is-selected-topic {
              border-bottom: 2px solid #08ddfd;
            }
          }
        }

        .is-selected-nav {
          h3 {
            border-bottom: 2px solid #08ddfd;
          }
        }

        .lecture-nav {
          text-align: center;
          margin-top: 4rem;

          h3 {
            font-size: 40px;
            font-weight: 300;
            color: #616161;
            margin-bottom: 1rem;
            display: inline-block;
          }

          ul {
            font-size: 30px;
            font-weight: 300;
            color: #616161;

            li {
              margin-bottom: 1rem;
              cursor: pointer;
            }

            .is-selected-topic {
              color: #08ddfd;
            }
          }
        }

        .board-nav {
          text-align: center;
          margin-top: 4rem;

          h3 {
            font-size: 40px;
            font-weight: 300;
            color: #616161;
            margin-bottom: 1rem;
            display: inline-block;
          }

          ul {
            font-size: 30px;
            font-weight: 300;
            color: #616161;
            li {
              margin-bottom: 1rem;
              cursor: pointer;
            }
          }
        }

        .notice-nav {
          text-align: center;
          margin-top: 4rem;

          h3 {
            display: inline-block;
            font-size: 40px;
            font-weight: 300;
            color: #616161;
            margin-bottom: 1rem;
            cursor: pointer;
            display: inline-block;
          }
        }

        .go-to-cms {
          display: flex;
          font-size: 20px;
          color: #08ddfd;
          cursor: pointer;
          align-items: center;
          border: 1px solid #08ddfd;
          border-radius: 64px;
          width: 100%;
          padding: 0.5rem 1rem;
          justify-content: center;
          margin-top: 6rem;

          img {
            margin-left: 1rem;
          }
        }

        .go-to-lms {
          display: flex;
          font-size: 20px;
          color: #07fdc0;
          cursor: pointer;
          align-items: center;
          border: 1px solid #07fdc0;
          border-radius: 64px;
          width: 100%;
          padding: 0.5rem 1rem;
          justify-content: center;
          margin-top: 6rem;

          img {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .is-mobile-menu-open {
    .mobile-menu-overlay {
      opacity: 1;
      transform: none;
      transition: opacity 0.3s ease 0s;
    }

    .mobile-menu-slide {
      transform: none;
    }
  }
}

.header-popup {
  padding: 6rem 2rem;
  .header-btns {
    display: flex;
    font-size: 60px;
    text-align: center;

    .header-myinfo {
      width: 50%;
      color: #08ddfd;
      border: 1px solid #08ddfd;
      border-radius: 64px;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      cursor: pointer;

      a {
        display: inline-block;
        width: 100%;
      }
    }

    .header-logout {
      width: 50%;
      color: #fff;
      border: 1px solid #616161;
      background: #616161;
      border-radius: 64px;
      padding: 0.5rem 1rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 1500px) {
  .Header {
    .header-wrap {
      .header-nav {
        ul {
          li {
            margin-left: 3rem;
          }
        }
      }

      .header-right {
        .go-to-cms {
          font-size: 18px;
          margin-right: 2rem;
        }

        .go-to-lms {
          font-size: 18px;
          margin-right: 2rem;
        }
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .Header {
    .header-wrap {
      .header-title {
        font-size: 22px;
      }
      .header-nav {
        ul {
          font-size: 22px;
          li {
            margin-left: 2rem;
          }
        }
      }

      .header-right {
        .go-to-cms {
          margin-right: 1rem;
        }
        .go-to-lms {
          margin-right: 1rem;
        }
      }
    }
  }

  .header-popup {
    padding: 6rem 2rem;
    .header-btns {
      font-size: 40px;
    }
  }
}

@media all and (max-width: 1024px) {
  .Header {
    .header-wrap {
      .header-title {
        font-size: 14px;
      }

      .header-nav {
        ul {
          font-size: 14px;
        }
      }

      .header-right {
        .go-to-cms {
          font-size: 12px;
        }
        .go-to-lms {
          font-size: 12px;
        }
      }
    }
  }

  .header-popup {
    padding: 6rem 2rem;
    .header-btns {
      font-size: 30px;
    }
  }
}

@media all and (max-width: 768px) {
  .Header {
    .header-wrap {
      display: none;
    }

    .header-mobile-wrap {
      display: flex;
    }

    .MobileMenu {
      display: block;
    }
  }
}

@media all and (max-width: 500px) {
  .Header {
    .header-wrap {
      display: none;
    }

    .header-mobile-wrap {
      display: flex;

      .header-mobile-title {
        font-size: 18px;
      }
    }

    .MobileMenu {
      .mobile-menu-slide {
        .mobile-menu-content {
          .header-btns {
            font-size: 20px;
          }

          .cms-lecture-nav {
            ul {
              font-size: 26px;
            }
          }
          .lecture-nav {
            h3 {
              font-size: 26px;
            }

            ul {
              font-size: 20px;
            }
          }

          .board-nav {
            h3 {
              font-size: 26px;
            }

            ul {
              font-size: 20px;
            }
          }

          .notice-nav {
            h3 {
              font-size: 26px;
            }
          }

          .go-to-cms {
            font-size: 16px;
            word-break: keep-all;
          }
          .go-to-lms {
            font-size: 16px;
            word-break: keep-all;
          }
        }
      }
    }
  }
}
