.MyInfoBanner {
  flex: 1;

  .myinfo-banner-bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 2rem;

    .myinfo-banner-title {
      font-weight: 900;
      font-size: 75px;
      color: #fff;
      line-height: 1.15;
      word-break: keep-all;
    }

    .myinfo-step {
      img {
        max-width: 100%;
      }

      p {
        word-break: keep-all;
        margin-top: 1rem;
        font-size: 38px;
        color: #fff;
        font-weight: 500;
      }

      .step-levels {
        display: flex;
        margin: 3rem 0;

        .step-level {
          margin-right: 1rem;
          width: 75px;
          height: 10px;
          background: #fff;
          opacity: 0.4;
        }

        .current-step {
          opacity: 1;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .MyInfoBanner {
    .myinfo-banner-bg {
      .myinfo-banner-title {
        font-size: 40px;
      }

      .myinfo-step {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .MyInfoBanner {
    width: 100%;
    text-align: center;

    .myinfo-banner-bg {
      position: relative;
      width: 100%;
      height: 50%;
      padding: 1rem 2rem;

      .myinfo-banner-title {
        word-break: keep-all;
        font-size: 60px;
      }

      .myinfo-step {
        margin-top: 2rem;

        img {
          max-width: 30%;
        }

        p {
          word-break: keep-all;
          text-align: center;
          font-size: 24px;
        }
        .step-levels {
          justify-content: center;
          margin: 2rem 0;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MyInfoBanner {
    .myinfo-banner-bg {
      .myinfo-banner-title {
        font-size: 40px;
      }

      .myinfo-step {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
