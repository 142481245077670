.CmsLectureBox {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .CmsLectureList {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    width: 100%;

    .cms-lecture-item {
      width: calc(20% - 4rem);
      margin: 1rem 2rem;
      cursor: pointer;

      .lecture-thumbnail {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 32px;

        img {
          max-width: 100%;
          border-radius: inherit;
        }
      }

      .lecture-title {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #757575;
        word-break: break-all;
        margin-top: 1rem;
      }
    }
  }
  .lecture-pagination {
    margin: 2rem 0;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      .previous {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: #08ddfd;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
        cursor: pointer;

        a {
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
        }
      }
      .page {
        width: 1rem;
        height: 1rem;
        background: #909090;
        border-radius: 50%;
        margin: 0 1rem;
        cursor: pointer;
        a {
          color: transparent;
          width: 1rem;
          height: 1rem;
          display: inline-block;
        }
      }

      .selected {
        background: #08ddfd;
      }
      .next {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: #08ddfd;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-left: 0.5rem;
        cursor: pointer;

        a {
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}

@media all and (max-width: 1500px) {
  .CmsLectureBox {
    .CmsLectureList {
      .cms-lecture-item {
        width: calc(25% - 4rem);
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .CmsLectureBox {
    .CmsLectureList {
      .cms-lecture-item {
        width: calc(33.33% - 4rem);
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .CmsLectureBox {
    .CmsLectureList {
      .cms-lecture-item {
        width: calc(50% - 4rem);

        .lecture-title {
          font-size: 16px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .CmsLectureBox {
    .CmsLectureList {
      .cms-lecture-item {
        .lecture-title {
          font-size: 30px;
        }
      }
    }

    .lecture-pagination {
      ul {
        .page {
          margin: 0 0.5rem;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .CmsLectureBox {
    .CmsLectureList {
      .cms-lecture-item {
        width: calc(100% - 4rem);
        .lecture-title {
          font-size: 20px;
        }
      }
    }

    .lecture-pagination {
      ul {
        .previous {
          display: none;
        }
        .next {
          display: none;
        }
      }
    }
  }
}
