.NoticeBox {
  display: flex;

  .notice-menu-list {
    display: flex;
    flex-direction: column;
    flex: 0.15;

    .notice-menu-item {
      flex: 1;
      padding: 1rem;
      color: #fff;
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #07fdc0;
      height: 100%;

      .notice-menu-img {
        img {
          max-width: 100%;
        }
      }

      .notice-menu-title {
        font-size: 26px;
        margin-top: 1rem;
      }
    }

    .is-selected-menu {
      color: #1d4d9f;
    }
  }
}

@media all and (max-width: 1024px) {
  .NoticeBox {
    .notice-menu-list {
      flex: 0.2;
    }
  }
}

@media all and (max-width: 768px) {
  .NoticeBox {
    flex-direction: column;
    .notice-menu-list {
      flex-direction: row;

      .notice-menu-item {
        padding: 2rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .NoticeBox {
    flex-direction: column;
    .notice-menu-list {
      flex-direction: row;

      .notice-menu-item {
        .notice-menu-img {
          img {
            max-width: 70%;
          }
        }
        .notice-menu-title {
          font-size: 20px;
        }
      }
    }
  }
}
