.JoinAgree {
  width: 70%;
  text-align: center;
  padding: 0 6rem;
  margin: 3rem 0;

  .join-agree-title {
    font-size: 40px;
    font-weight: 500;
    color: #08ddfd;
    margin-bottom: 2rem;
  }
  .join-agree-boxes {
    .agree-contents-box {
      font-size: 24px;
      color: #d5d5d5;
      margin-bottom: 2rem;
      .agree-contents {
        border: 2px solid #d5d5d5;
        text-align: left;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        overflow-y: auto;
        min-height: 10rem;
        max-height: 10rem;
        line-height: 1.5;

        .delth1 {
          font-weight: bold;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .delth2 {
          padding: 0;
          margin: 0px 0px 0px 7%;
          list-style: none;

          .delth3 {
            text-indent: -30px;
            padding: 0px;
            margin: 0px 0px 0px 20px;
            list-style: none;
          }
        }
      }

      .agree-check {
        display: flex;
      }
    }
  }

  .join-all-agree {
    display: inline-block;
    margin-bottom: 4rem;
  }

  .join-agree-btns {
    display: flex;
    width: 50%;
    margin: 0 auto;

    .agree-cancel {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      color: #07fdc0;
      background: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-right: 2rem;
      cursor: pointer;
    }

    .agree-next {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      background: #07fdc0;
      color: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-left: 2rem;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 1280px) {
  .JoinAgree {
    .join-agree-btns {
      width: 100%;
    }
  }
}

@media all and (max-width: 1024px) {
  .JoinAgree {
    padding: 0 4rem;

    .join-agree-title {
      font-size: 21px;
    }

    .join-agree-boxes {
      .agree-contents-box {
        .agree-contents {
          font-size: 12px;
        }
      }
    }

    .join-agree-btns {
      width: 100%;
      .agree-cancel {
        font-size: 18px;
      }

      .agree-next {
        font-size: 18px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .JoinAgree {
    width: 100%;
    padding: 0 2rem;

    .join-agree-title {
      font-size: 40px;
    }

    .join-agree-boxes {
      .agree-contents-box {
        .agree-contents {
          font-size: 24px;
        }
      }
    }

    .join-agree-btns {
      width: 100%;
      .agree-cancel {
        font-size: 32px;
        margin-right: 1rem;
      }

      .agree-next {
        font-size: 32px;
        margin-left: 1rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .JoinAgree {
    .join-agree-title {
      font-size: 26px;
    }

    .join-agree-boxes {
      .agree-contents-box {
        .agree-contents {
          font-size: 16px;
        }
      }
    }

    .join-agree-btns {
      width: 100%;
      .agree-cancel {
        font-size: 20px;
      }

      .agree-next {
        font-size: 20px;
      }
    }
  }
}
