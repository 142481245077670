.BoardBox {
  display: flex;
  height: 100%;

  .board-menu-list {
    display: flex;
    flex-direction: column;

    .board-menu-item-0 {
      background: #07fdc0;
    }
    .board-menu-item-1 {
      background: #00fff0;
    }
    .board-menu-item {
      flex: 1;
      padding: 4rem;
      color: #fff;
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .board-menu-img {
        img {
          max-width: 100%;
        }
      }

      .board-menu-title {
        font-size: 26px;
        margin-top: 1rem;
      }
    }

    .is-selected-menu {
      color: #1d4d9f;
    }
  }
}

@media all and (max-width: 768px) {
  .BoardBox {
    flex-direction: column;
    .board-menu-list {
      flex-direction: row;

      .board-menu-item {
        padding: 2rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .BoardBox {
    flex-direction: column;
    .board-menu-list {
      flex-direction: row;

      .board-menu-item {
        .board-menu-img {
          img {
            max-width: 70%;
          }
        }
        .board-menu-title {
          font-size: 20px;
        }
      }
    }
  }
}
