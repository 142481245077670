.CmsMinorTopicList {
  flex: 0.3;

  .minor-topic-list {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;

    .minor-topic-title {
      font-size: 24px;
      color: #616161;
      font-weight: 500;
      padding: 1rem;
      cursor: pointer;
      word-break: keep-all;
      border-bottom: 3px solid #fff;
    }

    .is-selected-topic {
      border-bottom: 3px solid #08ddfd;
    }
  }
}

@media all and (max-width: 1500px) {
  .CmsMinorTopicList {
    flex: 0.4;

    .minor-topic-list {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}

@media all and (max-width: 1280px) {
  .CmsMinorTopicList {
    flex: 0.45;

    .minor-topic-list {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}

@media all and (max-width: 1024px) {
  .CmsMinorTopicList {
    flex: 0.5;

    .minor-topic-list {
      padding-left: 2rem;
      padding-right: 2rem;

      .minor-topic-title {
        font-size: 12px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .CmsMinorTopicList {
    background: #f1f1f2;

    .minor-topic-list {
      display: flex;
      flex-wrap: wrap;
      padding: 1.5rem 1rem;

      .minor-topic-title {
        font-size: 24px;
        border: 3px solid transparent;
      }

      .is-selected-topic {
        border: 3px solid #07fdc0;
        border-radius: 52px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .CmsMinorTopicList {
    .minor-topic-list {
      .minor-topic-title {
        font-size: 16px;
      }
    }
  }
}
