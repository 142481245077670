.MainBanner {
  display: flex;
  height: 100%;

  .main-banner-bg {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 38vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-content: center;
    align-items: center;
    justify-content: center;

    .main-banner-title {
      display: none;
      text-align: center;
      font-size: 44px;
      padding: 3rem 2rem;
      color: #fff;
      word-break: keep-all;

      h2 {
        font-size: 44px;
        font-weight: 800;
      }
    }
  }

  .main-banner-box {
    flex: 0.4;
    background: #08ddfd;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .main-banner-title {
      text-align: center;
      font-size: 52px;
      color: #fff;
      word-break: keep-all;

      h2 {
        font-size: 52px;
        font-weight: 800;
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .MainBanner {
    .main-banner-box {
      .main-banner-title {
        font-size: 36px;

        h2 {
          font-size: 36px;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .MainBanner {
    .main-banner-box {
      .main-banner-title {
        font-size: 28px;

        h2 {
          font-size: 28px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .MainBanner {
    flex-direction: column;

    .main-banner-bg {
      .main-banner-title {
        display: block;
      }
    }

    .main-banner-box {
      display: none;
    }
  }
}
