.MainProgress {
  display: flex;
  max-height: 546px;

  .main-content-box {
    flex: 1;
    display: flex;

    .main-content-main-list {
      background: #f1f1f2;

      ul {
        .major-topic-0 {
          background: #07fdc0;
        }

        .major-topic-1 {
          background: #00fff0;
        }
        .major-topic-2 {
          background: #08ddfd;
        }

        text-align: center;

        .major-topic-box {
          display: flex;
          justify-content: center;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          padding: 1.5rem 1rem;
          cursor: pointer;
          word-break: keep-all;

          .major-topic-selected {
            color: #1d4d9f;
          }
        }
      }
    }

    .main-content-minor-list {
      flex: 1;
      overflow: auto;

      .not-found-topics {
        font-size: 32px;
        text-align: center;
        margin: 8rem 0;
        word-break: keep-all;
      }

      .minor-topic-box {
        position: relative;
        margin: 2rem;
        padding: 2rem;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        cursor: pointer;
        background: #fff;

        .minor-topic-top {
          display: flex;
          flex-direction: row-reverse;

          .minor-topic-status {
            font-size: 16px;
            color: #fff;
            text-align: center;

            .topic-in-progress {
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              background: #07fdc0;
              border-radius: 50%;
              box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2);
              width: 72px;
              height: 72px;
              word-break: keep-all;
            }

            .topic-complated {
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              background: #606060;
              border-radius: 50%;
              box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2);
              width: 72px;
              height: 72px;
              word-break: keep-all;
            }
          }
          .minor-title {
            flex: 1;
            font-size: 36px;
            color: #616161;
            font-weight: 500;
            text-align: center;
            margin: 1rem 0 2rem 0rem;
            word-break: keep-all;
          }
        }

        .minor-progress-info {
          display: flex;
          align-items: center;

          .minor-progress {
            display: contents;
          }
          .minor-progress-value {
            margin-left: 1rem;
            font-size: 24px;
            font-weight: 500;
            color: #616161;
          }
        }
      }

      .main-mobile-content-progress-list {
        background: #f1f1f2;
        overflow: auto;
        max-height: 360px;
        margin: 2rem;
        margin-top: -3rem;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .MainProgress {
    .main-content-box {
      .main-content-minor-list {
        .minor-topic-box {
          .minor-topic-top {
            .minor-topic-status {
              font-size: 16px;
            }

            .minor-title {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .MainProgress {
    max-height: unset;

    .main-content-box {
      flex: 1;
      flex-direction: column;

      .main-content-main-list {
        ul {
          display: flex;
          li {
            flex: 1;
          }
        }
      }

      .main-content-minor-list {
        .minor-topic-box {
          .minor-topic-top {
            .minor-topic-status {
              font-size: 16px;
            }
            .minor-title {
              font-size: 32px;
            }
          }

          .minor-progress-info {
            .minor-progress-value {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MainProgress {
    .main-content-box {
      .main-content-main-list {
        ul {
          .major-topic-box {
            font-size: 16px;
            padding: 1rem 0.5rem;
            word-break: keep-all;

            img {
              max-width: 80%;
              margin-bottom: 1rem;
            }
          }
        }
      }

      .main-content-minor-list {
        .minor-topic-box {
          padding: 1.5rem 1rem;

          .minor-topic-top {
            .minor-topic-status {
              font-size: 12px;
              .topic-in-progress {
                width: 56px;
                height: 56px;
              }
              .topic-complated {
                width: 56px;
                height: 56px;
              }
            }
            .minor-title {
              font-size: 20px;
            }
          }

          .minor-progress-info {
            .minor-progress-value {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
