.LoginBox {
  flex: 0.4;
  text-align: center;
  padding: 0 4rem;
  margin: 2rem 0;
  width: 100%;

  .login-title {
    font-size: 40px;
    color: #07fdc0;
    font-weight: 500;
    margin: 2rem 0;
  }

  .login-inputs {
    width: 100%;

    .login-input {
      margin-bottom: 2rem;
      width: 100%;

      input {
        width: 100%;
        font-size: 24px;
        font-weight: 500;
        padding: 1rem 1.5rem;
        border: 2px solid #d5d5d5;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        -webkit-appearance: none;
        outline: none;
        &:focus {
          border: 2px solid #08ddff;
          box-shadow: 0px 0px 10px #0054ff;
        }
      }
    }
  }

  .keep-sign-in {
    display: flex;
    align-items: center;

    .container {
      display: block;
      position: relative;
      padding-left: 3rem;
      cursor: pointer;
      font-size: 24px;
      color: #d5d5d5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        outline: none;
      }
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 36px;
      width: 36px;
      border-radius: 6px;
      border: 2px solid #d5d5d5;
      background-color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      border: 2px solid #07fdc0;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 10px;
      top: 2px;
      width: 10px;
      height: 18px;
      border: solid #07fdc0;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .error-msg {
    margin: 2rem 0;

    label {
      color: #ff0000;
      font-size: 18px;
      font-weight: 400;
      word-break: keep-all;
    }
  }

  .login-btn-box {
    width: 100%;
    margin: 1rem 0;
    text-align: center;
    .login-btn {
      width: 100%;
      border-radius: 16px;
      padding: 1rem 2rem;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      background: #07fdc0;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      outline: none;
      cursor: pointer;
    }
  }

  .sign-up-link {
    display: inline-block;
    margin-top: 6rem;
    font-size: 24px;
    font-weight: 400;
    color: #08ddfd;
    cursor: pointer;
  }
}

@media all and (max-width: 1280px) {
  .LoginBox {
    flex: 0.7;
  }
}
@media all and (max-width: 1024px) {
  .LoginBox {
    flex: 0.8;
    .login-title {
      font-size: 38px;
    }

    .login-inputs {
      .login-input {
        input {
          font-size: 14px;
        }
      }
    }

    .keep-sign-in {
      .container {
        font-size: 14px;

        .checkmark {
          top: -6px;
        }
      }
    }

    .login-btn-box {
      .login-btn {
        font-size: 16px;
      }
    }

    .sign-up-link {
      font-size: 16px;
    }
  }
}

@media all and (max-width: 768px) {
  .LoginBox {
    padding: 0 2rem;

    .login-title {
      font-size: 40px;
    }

    .login-inputs {
      .login-input {
        input {
          font-size: 24px;
        }
      }
    }

    .keep-sign-in {
      .container {
        font-size: 24px;

        .checkmark {
          top: 0px;
        }
      }
    }

    .error-msg {
      label {
        font-size: 20px;
      }
    }

    .login-btn-box {
      .login-btn {
        font-size: 32px;
      }
    }

    .sign-up-link {
      font-size: 24px;
    }
  }
}

@media all and (max-width: 500px) {
  .LoginBox {
    .login-title {
      font-size: 26px;
    }

    .login-inputs {
      .login-input {
        input {
          font-size: 16px;
        }
      }
    }

    .keep-sign-in {
      .container {
        font-size: 16px;

        .checkmark {
          top: -6px;
        }
      }
    }

    .error-msg {
      label {
        font-size: 12px;
      }
    }

    .login-btn-box {
      .login-btn {
        font-size: 20px;
      }
    }

    .sign-up-link {
      font-size: 16px;
    }
  }
}
