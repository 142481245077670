.QaBox {
  flex: 1;
  text-align: center;

  .qa-title {
    font-size: 40px;
    color: #08ddfd;
    font-weight: 500;
    margin: 2rem 0;
  }

  .qa-input-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.5rem;
    padding: 0 2rem;

    .qa-input-label {
      flex: 0.1;
      font-size: 30px;
      color: #3f3f3f;
      font-weight: 500;
      margin-right: 1rem;
      word-break: keep-all;
    }

    .qa-input {
      flex: 1;

      input[type='text'] {
        width: 100%;
        border-radius: 32px;
        outline: none;
        border: 1px solid #f1f1f1;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        -webkit-appearance: none;
        font-size: 30px;
        padding: 0.5rem 1.5rem;

        &::placeholder {
          font-size: 0px;
          color: #d5d5d5;
        }
      }

      textarea {
        width: 100%;
        border-radius: 32px;
        outline: none;
        border: 1px solid #f1f1f1;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        -webkit-appearance: none;
        font-size: 30px;
        padding: 0.5rem 1.5rem;
        &::placeholder {
          font-size: 0px;
          color: #d5d5d5;
        }
      }
      .dropzone {
        display: flex;

        .qa-file-list {
          flex: 1;
          width: 100%;
          border-radius: 32px;
          outline: none;
          border: 1px solid #f1f1f1;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
          -webkit-appearance: none;
          font-size: 30px;
          padding: 0.5rem 1.5rem;
          margin-right: 1rem;
          text-align: left;
          display: flex;
          align-items: center;

          .placeholder {
            font-size: 22px;
            color: #d5d5d5;
            word-break: keep-all;
          }

          ul {
            li {
              display: inline-block;

              .file-info {
                margin-right: 1.5rem;
                display: flex;
                align-items: center;

                .file-x {
                  float: right;
                  margin-left: 0.5rem;
                  background: #f1f1f2;
                  padding: 0.05rem 0.875rem;
                  border-radius: 6px;
                  cursor: pointer;
                  text-align: center;
                }
              }
            }
          }
        }

        .qa-file-input {
          flex: 0.3;

          button {
            width: 100%;
            background: #07fdc0;
            outline: none;
            border-radius: 52px;
            border: 1px solid #07fdc0;
            cursor: pointer;
            color: #fff;
            font-size: 32px;
            padding: 0.5rem 1.5rem;
          }
        }
      }
    }
  }

  .qa-btn-box {
    margin: 3rem 0;

    .qa-btn {
      display: inline-block;
      border-radius: 32px;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      background: #08ddfd;
      padding: 0.5rem 6rem;
      cursor: pointer;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }
  }
}

@media all and (max-width: 1280px) {
  .QaBox {
    .qa-input-box {
      .qa-input-label {
        font-size: 24px;
      }

      .qa-input {
        input[type='text'] {
          font-size: 24px;
        }

        textarea {
          font-size: 24px;
        }
        .dropzone {
          .qa-file-list {
            font-size: 24px;

            .placeholder {
              font-size: 20px;
            }
          }

          .qa-file-input {
            flex: 0.4;

            button {
              font-size: 24px;
            }
          }
        }
      }
    }

    .qa-btn-box {
      .qa-btn {
        font-size: 24px;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .QaBox {
    .qa-title {
      font-size: 22px;
    }
    .qa-input-box {
      .qa-input-label {
        font-size: 16px;
      }

      .qa-input {
        input[type='text'] {
          font-size: 16px;
        }

        textarea {
          font-size: 16px;
        }

        .dropzone {
          .qa-file-list {
            font-size: 16px;

            .placeholder {
              font-size: 14px;
            }
          }
          .qa-file-input {
            flex: 0.6;

            button {
              font-size: 16px;
            }
          }
        }
      }
    }

    .qa-btn-box {
      .qa-btn {
        font-size: 18px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .QaBox {
    .qa-title {
      font-size: 40px;
    }
    .qa-input-box {
      .qa-input-label {
        display: none;
        font-size: 30px;
      }

      .qa-input {
        input[type='text'] {
          font-size: 30px;
          &::placeholder {
            font-size: inherit;
          }
        }

        textarea {
          font-size: 30px;
          &::placeholder {
            font-size: inherit;
          }
        }
        .dropzone {
          flex-direction: column;
          .qa-file-list {
            padding: 1rem;
            margin-bottom: 1rem;
            font-size: 30px;

            .placeholder {
              font-size: 28px;
            }
          }

          .qa-file-input {
            button {
              font-size: 30px;
            }
          }
        }
      }
    }

    .qa-btn-box {
      padding: 0 2rem;

      .qa-btn {
        width: 100%;
        font-size: 32px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .QaBox {
    .qa-title {
      font-size: 26px;
    }
    .qa-input-box {
      .qa-input {
        input[type='text'] {
          font-size: 22px;
        }

        textarea {
          font-size: 22px;
        }

        .dropzone {
          .qa-file-list {
            font-size: 22px;

            .placeholder {
              font-size: 20px;
            }
          }

          .qa-file-input {
            button {
              font-size: 30px;
            }
          }
        }
      }
    }

    .qa-btn-box {
      .qa-btn {
        font-size: 22px;
      }
    }
  }
}
