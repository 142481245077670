.JoinBox {
  width: 70%;
  text-align: center;
  padding: 0 20rem;
  margin: 3rem 0;

  .join-title {
    font-size: 40px;
    font-weight: 500;
    color: #08ddfd;
    margin-bottom: 2rem;
  }

  .join-inputs {
    .join-input-box {
      margin-bottom: 3rem;

      .join-input {
        display: flex;
        align-items: center;

        input[type='text'],
        input[type='password'] {
          width: 100%;
          font-size: 24px;
          font-weight: 500;
          padding: 1rem 1.5rem;
          border: 2px solid #d5d5d5;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
          -webkit-appearance: none;
          outline: none;
          &:focus {
            border: 2px solid #08ddff;
            box-shadow: 0px 0px 10px #0054ff;
          }
        }

        .duplicate-check {
          height: 100%;
          width: 50%;
          padding: 1rem 1.5rem;
          background: #08ddfd;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          margin-left: 1rem;
          cursor: pointer;
          word-break: keep-all;
        }

        .phone-code {
          width: 50%;
          margin-right: 1rem;
        }

        .member-type {
          width: 100%;
        }
      }

      .join-notify {
        text-align: center;
        word-break: keep-all;
        margin-top: 1rem;
        font-size: 18px;
        color: #ff0000;
      }
    }
  }

  .join-btns {
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 6rem;

    .join-cancel {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      color: #07fdc0;
      background: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-right: 2rem;
      cursor: pointer;
    }

    .join-next {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      background: #07fdc0;
      color: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-left: 2rem;
      cursor: pointer;
    }
  }
}

.join-popup {
  text-align: center;
  padding: 4rem 4rem;
  border-radius: 16px;

  .join-popup-title {
    font-size: 40px;
    font-weight: 400;
    color: #000;
  }

  .join-btns {
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;

    .join-cancel {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      color: #07fdc0;
      background: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-right: 2rem;
      cursor: pointer;
    }

    .join-next {
      width: 100%;
      font-size: 32px;
      font-weight: 500;
      background: #07fdc0;
      color: #fff;
      border-radius: 32px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      padding: 0.5rem 2rem;
      margin-left: 2rem;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 1760px) {
  .JoinBox {
    padding: 0 10%;
  }
}

@media all and (max-width: 1280px) {
  .JoinBox {
    padding: 0 4rem;
  }
}

@media all and (max-width: 1024px) {
  .JoinBox {
    .join-title {
      font-size: 22px;
    }

    .join-inputs {
      .join-input-box {
        .join-input {
          input[type='text'],
          input[type='password'] {
            font-size: 12px;
          }

          .DropDown {
            .drop-down {
              font-size: 12px;
            }
          }

          .duplicate-check {
            font-size: 12px;
          }
        }
        .join-notify {
          font-size: 10px;
        }
      }
    }

    .join-btns {
      margin-top: 4rem;
      .join-cancel {
        font-size: 18px;
      }
      .join-next {
        font-size: 18px;
      }
    }
  }

  .popup-content {
    width: 70% !important;
  }

  .join-popup {
    padding: 4rem 2rem;

    .join-popup-title {
      font-size: 23px;
    }
    .join-btns {
      .join-cancel {
        margin-right: 1rem;
        font-size: 20px;
      }
      .join-next {
        margin-left: 1rem;
        font-size: 20px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .JoinBox {
    width: 100%;
    padding: 0 2rem;
    .join-title {
      font-size: 40px;
    }

    .join-inputs {
      .join-input-box {
        .join-input {
          flex-direction: column;

          input[type='text'],
          input[type='password'] {
            font-size: 30px;
          }

          .phone-code {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
          }

          .DropDown {
            .drop-down {
              font-size: 30px;
            }
          }

          .duplicate-check {
            margin-left: 0;
            margin-top: 1rem;
            width: 100%;
            font-size: 30px;
          }
        }
        .join-notify {
          font-size: 24px;
        }
      }
    }

    .join-btns {
      margin-top: 6rem;
      .join-cancel {
        font-size: 32px;
      }
      .join-next {
        font-size: 32px;
      }
    }
  }

  .popup-content {
    width: 80% !important;
  }
  .join-popup {
    padding: 4rem 1rem;

    .join-popup-title {
      font-size: 34px;
    }
    .join-btns {
      .join-cancel {
        font-size: 26px;
      }
      .join-next {
        font-size: 26px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .JoinBox {
    .join-title {
      font-size: 26px;
    }

    .join-inputs {
      .join-input-box {
        .join-input {
          flex-direction: column;

          input[type='text'],
          input[type='password'] {
            font-size: 20px;
          }

          .DropDown {
            .drop-down {
              font-size: 20px;
            }
          }

          .duplicate-check {
            font-size: 20px;
          }
        }
        .join-notify {
          font-size: 16px;
        }
      }
    }

    .join-btns {
      margin-top: 4rem;
      .join-cancel {
        margin-right: 1rem;
        font-size: 20px;
      }
      .join-next {
        margin-left: 1rem;
        font-size: 20px;
      }
    }
  }

  .popup-content {
    width: 90% !important;
  }
  .join-popup {
    padding: 2rem 1rem;

    .join-popup-title {
      font-size: 22px;
    }
    .join-btns {
      .join-cancel {
        font-size: 18px;
      }
      .join-next {
        font-size: 18px;
      }
    }
  }
}
